<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start">

                                     <v-col class="d-flex" cols="12" sm="4">
                                        <v-select class="mt-5"
                                                  :items="customYear"
                                                  dense
                                                  outlined
                                                  :label="$t('from_date')"
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"
                                                  ></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-select class="mt-5"
                                                  :items="customYear"
                                                  :label="$t('to_date')"
                                                  v-model="serverParams.ToDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                  outlined></v-select>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
            <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
        </span>-->
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.hotel'),
                        exact: true,
                        to: '/reportModules/reportHotel'
                    },
                    {
                        text: this.$t('modules_name.monthly_yearly_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {

                let param = {
                    ToDate: this.serverParams.ToDate,
                    FromDate: this.serverParams.FromDate,
                };
                await axios({
                    url: 'Hotel/DownloadHotelReport',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'HotelFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
                //await axios({
                //    url: 'Immigration/DownloadAverageLengthOfStay',
                //    method: 'POST',
                //    responseType: 'blob',
                //    data: param
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'AverageLengthOfStay.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();

                //});
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    /* PageSize: this.serverParams.perPage,
                     PageNo: this.serverParams.page,*/
                    /* ReportType: this.serverParams.ReportType,*/
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                    /* OrderType: this.serverParams.sort[0].type,
                     OrderBy: this.serverParams.sort[0].field,*/
                    /* FromDate: this.serverParams.FromDate,
                     ToDate: this.serverParams.ToDate,
                     ReportType: this.serverParams.ReportType,*/
                };
                axios.post('Hotel/GetHotelReport', param)
                    .then(response => {
                        this.rows = response.data;
                        // this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'year',
                        field: 'year'
                    },
                    {
                        label: 'five_star_hotel',
                        field: 'fiveStar'
                    },
                    {
                        label: 'five_star_hotel_bed',
                        field: 'fiveStarBed'
                    },
                    {
                        label: 'four_star_hotel',
                        field: 'fourStar'
                    },
                    {
                        label: 'four_star_hotel_bed',
                        field: 'fourStarBed'
                    },
                    {
                        label:'three_star_hotel',
                        field: 'threeStar'
                    },
                    {
                        label: 'three_star_hotel_bed',
                        field: 'threeStarBed'
                    },
                    {
                        label: 'non_star_hotel',
                        field: 'nonStarHotel'
                    },
                    {
                        label: 'non_star_hotel_bed',
                        field: 'nonStarHotelBed'
                    },
                    {
                        label: 'resort_hotel',
                        field: 'resortHotel'
                    },
                    {
                        label: 'resort_hotel_bed',
                        field: 'resortHotelBed'
                    },
                    {
                        label: 'boutique_hotel',
                        field: 'boutiqueHotel'
                    },
                    {
                        label: 'boutique_hotel_bed',
                        field: 'boutiqueHotelBed'
                    },
                    {
                        label: 'total_hotels',
                        field: 'totalHotel'
                    },
                    {
                        label: 'total_bed',
                        field: 'totalBed'
                    },
                ],
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
